<template>
    <div class="height1">
        <!--      头部      -->
        <div class="iq-card-body">
            <div class="pay-top">
                <p class="payItem">收款账户：<span></span></p>
                <p class="payItem">收款日期：<span></span></p>
                <p class="payNum">应付金额：<span>0.2</span> 元</p>
            </div>
            <el-divider></el-divider>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PayPage"
    }
</script>

<style scoped>
    .pay-top{
        position: relative;
        padding: 10px;
    }
    .payItem{
        line-height: 2;
        font-size: 18px;
        color: #666666;
        font-weight: 600;
    }
    .payNum{
        position: absolute;
        right: 50px;
        top: 30px;
        font-size: 16px;
    }
    .payNum>span{
        color: red;
        font-size: 24px;
    }
</style>
